<template id="social-link-template">
  <a
    v-if="showIcon"
    class="social-link"
    :href="url"
    target="_blank"
    :title="title"
    rel="noreferrer"
    @click="copyToClipboard"
    ><span :class="iconClass"></span
  ></a>
</template>

<script>
/* eslint-disable */
import { isMobile } from "@/helpers.js";
export default {
  name: "social-link",
  template: "#social-link-template",
  props: [
    "url", 
    "type",
    "closed",
    "source"
  ],
  computed: {
    title: function () {
      return this.type.charAt(0).toUpperCase() + this.type.substr(1).toLowerCase();
    },
    iconClass: function () {
      if(this.type == "rssfeed") {
        return "icon-rss-solid";
      }
      return "icon-" + this.type;
    },
    showIcon: function () {
      if (this.closed && this.type == "rssfeed") {
        return false;
      }
      return true;
    },
    rssSource: function() {
      if (!this.source) {
        return "Show";
      }
      return this.source;
    }
  },
  data: function() {
    return {
    }
  },
  methods: {
    isMobile,
    copyToClipboard: function(e) {
      const comp = this;
      if(this.type != "rssfeed") {
        return true;
      }
      if(navigator.clipboard) {
        navigator.clipboard.writeText(this.url).then(
          function () {
            comp.$toasted.show(`${comp.rssSource} feed URL was copied to clipboard.<br />Now you can add it to your favourite RSS reader or podcast application.`);
          },
          function (e) {
            comp.$toasted.error(`${comp.rssSource} feed url is:\n` + e.text);
          }
        )
      } else {
        this.$copyText(this.url).then(function () {
          comp.$toasted.show(`${comp.rssSource} feed url was copied to clipboard.<br />Now you can add it to your favourite RSS reader or podcast application.`);
        }, function (e) {
          comp.$toasted.error(`${comp.rssSource} feed url is:\n` + e.text);
        })
      }
      e.preventDefault();
    },
  }
};
</script>

<style scoped lang="scss">
@import "../variables";
.social-link {
    cursor: pointer;
    font-size: 28px;
    color: $color-secondary;
    text-decoration: none;
    &.color-white {
      color: white ! important;
    }
}
</style>
