/*
 * helpers functions
 */

export function isMobile() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export function deviceType() {
  return isMobile() ? "mobile" : "desktop";
}

export function windowWidth() {
  return window.innerWidth;
}

export function showSearchInput() {
  const navToggle = document.getElementsByClassName("navigation-toggle")[0];
  const navStyles = window.getComputedStyle(navToggle);
  return navStyles.display == "flex";
}

export function formatDuration(value) {
  var hours = Math.floor(value / 3600);
  var minutes = Math.floor((value - hours * 3600) / 60);
  var seconds = Math.floor(value - minutes * 60 - hours * 3600);
  var val =
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0");
  val = hours.toString() + ":" + val;
  return val;
}

export function getStreamUrl() {
  if (
    window.location.host.startsWith("localhost") ||
    window.location.host.startsWith("127.0.0.1")
  ) {
    return "http://radiopunctum.cz:8000/radio";
  } else {
    return "https://" + window.location.host + ":8001/radio";
    //return "https://" + window.location.host + "/live-stream";
  }
}

export function getApiUrl() {
  if (
    window.location.host.startsWith("localhost") ||
    window.location.host.startsWith("127.0.0.1")
  ) {
    return "https://radiopunctum.cz/api/radio/";
  } else {
    return (
      window.location.protocol + "//" + window.location.host + "/api/radio/"
    );
  }
}

export function getShareLink(item) {
  return (
    window.location.protocol +
    "//" +
    window.location.host +
    "/archive/" +
    item.recording_name
  );
}

export function getSelectionShareLink(selectionId) {
  return (
    window.location.protocol +
    "//" +
    window.location.host +
    "/playlist/" +
    selectionId
  );
}

export function archiveFeedUrl() {
  return (
    window.location.protocol + "//" + window.location.host + "/" + "archive.rss"
  );
}

export function setItemsOrder(items) {
  let ordered = items.map(function(item, index) {
    item.order = index;
    if (item.position === undefined) {
      item.position = index;
    }
    return item;
  });
  return ordered;
}

export function getLinesForTitle(title) {
  // do nothing if title is already array
  if (Array.isArray(title)) {
    return title;
  }
  // for mobile device always split
  if (isMobile()) {
    return title.split(" ");
  } else {
    return [title];
  }
}

export const isString = value => {
  return typeof value === "string";
};

export const isBoolean = value => {
  return typeof value === "boolean";
};

export const isObject = value => {
  return typeof value === "object";
};

/*eslint no-prototype-builtins: "off"*/
export const has = (object, key) => {
  return isObject(object) && object.hasOwnProperty(key);
};

export const get = (object, key, defaultValue) => {
  return has(object, key) ? object[key] : defaultValue;
};

export const px = value => {
  return `${value}px`;
};

export const translate3d = (x, y, z = "0px") => {
  return `translate3d(${x}, ${y}, ${z})`;
};

export const localStorageRead = prop => {
  return JSON.parse(localStorage.getItem(prop));
};

export const localStorageWrite = (prop, value) => {
  localStorage.setItem(prop, JSON.stringify(value));
};

export const bytesToBase64 = bytes => {
  const binString = String.fromCodePoint(...bytes);
  return btoa(binString);
};

export const objectToBase64 = value => {
  const text = JSON.stringify(value);
  console.log("objectToBase64", text.length, text);
  return bytesToBase64(new TextEncoder().encode(text));
};

export const base64ToBytes = base64 => {
  const binString = atob(base64);
  return Uint8Array.from(binString, m => m.codePointAt(0));
};

export const base64ToObject = value => {
  return JSON.parse(new TextDecoder().decode(base64ToBytes(value)));
};

export function autoLink() {
  // this is a modified version of https://github.com/bryanwoods/autolink-js
  let callback, k, linkAttributes, option, options, pattern, v;

  options = 1 <= arguments.length ? [].slice.call(arguments, 0) : [];
  pattern = /(^|[\s\n]|<[A-Za-z]*\/?>)((?:https?|ftp):\/\/[-A-Z0-9+u0026\u2019@#/%?=()~_|!:,.;]*[-A-Z0-9+\u0026@#/%=~()_|])/gi;

  if (!(options.length > 0)) {
    return this.replace(pattern, "$1<a href='$2'>$2</a>");
  }

  option = options[0];
  callback = option["callback"];
  linkAttributes = (function() {
    const results = [];

    for (k in option) {
      v = option[k];
      if (k !== "callback") {
        results.push(" " + k + "='" + v + "'");
      }
    }
    return results;
  })().join("");

  return this.replace(pattern, function(match, space, url) {
    const link =
      (typeof callback === "function" ? callback(url) : void 0) ||
      "<a href='" + url + "'" + linkAttributes + ">" + url + "</a>";
    return "" + space + link;
  });
}

String.prototype["autoLink"] = autoLink;

export const formatShowRecordingDate = date => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "2-digit",
    year: "2-digit"
  });
};

export function sanitize(string) {
  const map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;"
  };
  const reg = /[&<>"'/]/gi;
  return string.replace(reg, match => map[match]);
}
