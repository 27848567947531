import Vue from "vue";
import LoadScript from "vue-plugin-load-script";
import VueClipboard from "vue-clipboard2";
import VueCookie from "vue-cookie";
import ScrollLoader from "vue-scroll-loader";
import Toasted from "vue-toasted";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./main.scss";

Vue.use(LoadScript);
Vue.use(ScrollLoader);
Vue.use(VueClipboard);
Vue.use(VueCookie);
Vue.use(Toasted, {
  singleton: true,
  duration: 7000
});
Vue.directive("focus", {
  inserted: function(el) {
    el.focus();
  }
});

Vue.config.productionTip = false;
Vue.http.options.credentials = true;

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  //  document.title = to.meta.title;
  next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
