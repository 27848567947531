<template id="program-list-template">
  <div class="program-items-grid">
    <program-item
      v-for="(item, idx) in items"
      ref="programItem"
      :name="item.name"
      :slug="item.slug"
      :image="item.image"
      :presenters="item.presenters"
      :tags="item.tags"
      :recent_recording="item.recent_recording"
      :closed="item.closed"
      :order="idx"
      :presenters_separator="item.presenters_separator"
      v-bind:key="idx"
    />
  </div>
</template>

<script>
/* eslint-disable */
import ProgramItem from "@/components/ProgramItem.vue";
export default {
    name: "program-item-list",
    template: "#program-list-template",
    props: [
      "items"
    ],
    components: {
        ProgramItem
    }
};
</script>

<style scoped lang="scss">
@import "../variables";
.program-items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 0;
  margin-bottom: $base-unit * 2;
  z-index: 0;

  @media (min-width: $screen-sm-min) {
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(289px, 1fr));
    grid-auto-rows: 1fr;
    margin-bottom: 0;
  }

  @media (max-width: $screen-xxlg-max) {
    grid-template-columns: repeat(auto-fill, minmax(410px, 1fr));
  }

  @media (max-width: $screen-xlg-max) {
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
  }

  @media (max-width: $screen-lg-max) {
    grid-template-columns: repeat(auto-fill, minmax(289px, 1fr));
  }

  @media (width: $screen-lg-min + 1) {
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
  }

  @media (max-width: $screen-md-max) {
    grid-template-columns: repeat(auto-fill, minmax(289px, 1fr));
  }

  @media (min-width: $screen-xxxl-min) {
    grid-template-columns: repeat(auto-fill, minmax(513px, 1fr));
  }

  @media (min-width: $screen-xxxxl-min) {
    grid-template-columns: repeat(auto-fill, minmax(643px, 1fr));
  }

  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
}
</style>
