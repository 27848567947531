<template id="calendar-day-template">
  <div class="calendar-weekday">
    <h3 class="calendar-weekday-name">
      <formatted-date
        :date="date"
        :showMonthShort="showMonth"
        :showYear="false"
        :showWeekday="true"
        :showMonthShortWhenDiffers="showMonthWhenDiffers"
      />
      <a
        v-if="location"
        :href="location.map_url"
        target="_blank"
        class="calendar-broadcast-location"
        :class="locationClass"
        >{{ location.name }}</a
      >
      <span v-else class="calendar-prerecorded" :class="locationClass"
        >Online</span
      >
    </h3>
    <ul>
      <router-link
        v-for="(show, idx) in shows"
        :to="targetLink(show)"
        :class="{ 'external-event': show.external_event }"
        v-bind:key="idx"
        tag="li"
      >
        <div v-if="show.external_event">
          {{ show.title }}
          <img
            v-if="show.image"
            :src="show.image"
            class="calendar-show-thumb"
            :alt="show.title"
          />
        </div>
        <div v-else>
          {{ formatTime(show.start) }} {{ show.title }}
          {{ formatPresenters(show.presenters, show.title_separator) }}
        </div>
      </router-link>
    </ul>
  </div>
</template>

<script>
/* eslint-disable */
import { isMobile, windowWidth } from "@/helpers.js";
import FormattedDate from "@/components/FormattedDate.vue";

export default {
    name: "calendar-day",
    template: "#calendar-day-template",
    props: [
        "date",
        "weekday",
        "location",
        "shows",
        "order",
        "showMonth",
        "showMonthWhenDiffers",
        "locationClass"
    ],
  components: {
    FormattedDate
  },
    computed: {
    },
    data: function() {
        return {
        }
    },
    mounted: function () {
    },
    methods: {
        formatTime: function(datetime) {
            return datetime 
              ? new Date(datetime).toLocaleTimeString("cs-CZ", {hour: "2-digit", minute: "2-digit", hour12: false})
              : null;
        },
        formatPresenters: function(presenters, title_separator) {
            if(!presenters) {
                return '';
            }
            if(presenters.length == 0) {
                return '';
            }
            if(presenters.length > 2) {
                return title_separator+" "+presenters.map(function(elem) {
                    return elem.name;
                }).join(", ");
            }
            return title_separator+" "+presenters.map(function(elem) {
                return elem.name;
            }).join(" & ");
        },
        formatLocation: function(loc) {
            if(loc) {
                return '@' + loc;
            }
            return '';
        },
        formatLineUp: function(lineup, loc) {
            if(lineup.length == 0) {
                return '';
            }
            return loc ? ", " : "" +lineup.slice(0,2).map(function(elem) {
                return elem;
            }).join(", ");
        },
        targetLink: function(show) {
            if(show.external_event) {
                return { name: 'EventDetail', params: { slug: show.slug }};
            }
            else {
                return { name: 'ShowDetail', params: { slug: show.slug }};
            }
        }
    }
};
</script>

<style lang="scss">
@import "../variables";

.calendar-prerecorded {
  padding: 1px 5px;
  color: white;
  border-radius: 2px;
  font-weight: 400;
  font-size: 90% !important;
  margin-right: $base-unit/2;
  font-family: $sans-serif;
  text-decoration: none;
  text-transform: capitalize;
  //background-color: #808080;

  @media (min-width: $screen-sm-min) {
    font-size: 85%;
  }
  
  &.on-green-background {
    color: $bg-color-alternative !important;
  }
}


.calendar-broadcast-location {
  padding: 1px 5px;
  //color: $color-primary;
  border-radius: 2px;
  font-weight: 400;
  font-size: 90% !important;
  margin-right: $base-unit/2;
  font-family: $sans-serif;
  text-decoration: underline;
  text-transform: capitalize;

  @media (min-width: $screen-sm-min) {
    font-size: 85%;
  }
  &:hover {
    text-decoration: none;
  }

  &.on-black-background {
    color: $player-live-broadcast-color;
  }
  
  &.on-green-background {
    color: $player-live-broadcast-color-secondary !important;
  }
}

.calendar-weekday {
    float: left;
}

.calendar-weekday-name {
    margin-bottom: $base-unit;
    padding-left: $base-unit * 3;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    font-family: $title-font;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-sm-min) {
        padding-left: 0;
    }
    sup {
        vertical-align: super;
        font-size: 12px;
    }
}

.calendar-weekday {
  li {
    cursor: pointer;
    border-radius: 7px;
    padding: 3px $base-unit * 3;
    font-size: 14px;

    @media (min-width: $screen-sm-min) {
        padding: 2px $base-unit;
    }

    &:nth-child(even) {
        background-color: $list-item-even-alternative;
    }
    &:nth-child(odd) {
        background-color: $list-item-odd-alternative;
    }
    &.external-event {
        padding: $base-unit * 2 $base-unit * 3 $base-unit * 3 $base-unit * 3;
        background-color: $color-secondary;
        color: $list-item-alternative;
    }

    &:hover {
        text-decoration: underline;
    }
  }
  &.loading li:hover {
    cursor: default;
    text-decoration: none;
  }
}
.content-alternative .calendar-weekday li {
    &:nth-child(even) {
        background-color: $list-item-even;
    }
    &:nth-child(odd) {
        background-color: $list-item-odd;
    }
    &.external-event {
        background-color: $list-item-alternative;
        color: #ffffff;
    }
}

.calendar-show-thumb {
    width: 100%;
    margin-top: $base-unit * 2;
}
</style>
