import radio from "../api";

const state = {
  shows: [
    {
      name: "███████████",
      slug: ".",
      presenters: [{ name: "███████████" }],
      tags: [{ tag: "███" }, { tag: "███" }, { tag: "███" }]
    },
    {
      name: "███████████",
      slug: ".",
      presenters: [{ name: "███████████" }],
      tags: [{ tag: "███" }, { tag: "███" }, { tag: "███" }]
    },
    {
      name: "███████████",
      slug: ".",
      presenters: [{ name: "███████████" }],
      tags: [{ tag: "███" }, { tag: "███" }, { tag: "███" }]
    },
    {
      name: "███████████",
      slug: ".",
      presenters: [{ name: "███████████" }],
      tags: [{ tag: "███" }, { tag: "███" }, { tag: "███" }]
    },
    {
      name: "███████████",
      slug: ".",
      presenters: [{ name: "███████████" }],
      tags: [{ tag: "███" }, { tag: "███" }, { tag: "███" }]
    },
    {
      name: "███████████",
      slug: ".",
      presenters: [{ name: "███████████" }],
      tags: [{ tag: "███" }, { tag: "███" }, { tag: "███" }]
    },
    {
      name: "███████████",
      slug: ".",
      presenters: [{ name: "███████████" }],
      tags: [{ tag: "███" }, { tag: "███" }, { tag: "███" }]
    },
    {
      name: "███████████",
      slug: ".",
      presenters: [{ name: "███████████" }],
      tags: [{ tag: "███" }, { tag: "███" }, { tag: "███" }]
    }
  ],
  recordings: [],
  isLoading: false,
  isLoadingRecordings: false,
  recentShow: undefined,
  recentShowSlug: undefined,
  page: 1,
  nextPage: true,
  recordingsPage: 1,
  nextRecordingsPage: true
};

/*eslint no-prototype-builtins: "off"*/
function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
    const rvarA = varA.replace("]", "").replace("[", "");
    const rvarB = varB.replace("]", "").replace("[", "");
    let comparison = rvarA.localeCompare(rvarB);
    return order === "desc" ? comparison * -1 : comparison;
  };
}

const getters = {
  getShows: state => {
    //return state.all.data;
    if (state.shows) {
      return state.shows;
      //return state.all.data.filter(show => !show.closed);
    }
  },
  getShowsAscending: state => {
    const data = [...state.shows];
    //const data = [...state.all.data.filter(show => !show.closed)];
    data.sort(compareValues("name"));
    return data;
  },
  getShowsDescending: state => {
    const data = [...state.shows];
    //const data = [...state.all.data.filter(show => !show.closed)];
    data.sort(compareValues("name", "desc"));
    return data;
  }
};

const actions = {
  loadShows({ commit, state }, { clear }) {
    commit("setLoadingState", true);
    radio
      .getShows(state.page, true, false)
      .then(response => {
        if (clear) {
          commit("clearShows");
        }
        commit("setLoadingState", false);
        commit("setShows", response);
      })
      .catch(error => {
        error;
      });
  },
  loadAllShows({ commit }) {
    commit("setLoadingState", true);
    radio
      .getShows(1, true, false, 50)
      .then(response => {
        commit("clearShows");
        commit("setLoadingState", false);
        commit("setShows", response);
      })
      .catch(error => {
        error;
      });
  },
  loadShowDetail({ commit }, slug) {
    commit("setLoadingState", true);
    radio
      .getShowDetail(slug)
      .then(response => {
        commit("setLoadingState", false);
        commit("setRecentShow", response);
      })
      .catch(error => {
        error;
      });
  },
  loadShowRecordings({ commit, state }, { slug, clear }) {
    commit("setLoadingRecordingsState", true);
    commit("setRecordingsLoadDisabled");
    if (clear) {
      commit("clearRecordings");
    }
    radio
      .getRecordings(slug, state.recordingsPage)
      .then(response => {
        commit("setLoadingRecordingsState", false);
        commit("setRecordings", response);
      })
      .catch(error => {
        error;
      });
  }
};

const mutations = {
  setLoadingState(state, bool) {
    state.isLoading = bool;
  },

  setLoadingRecordingsState(state, bool) {
    state.isLoadingRecordings = bool;
  },

  setShows(state, resultData) {
    state.page++;
    state.shows = [...state.shows, ...resultData.data];
    state.nextPage = resultData.next;
  },

  setRecentShow(state, resultData) {
    state.recentShow = resultData.data;
  },

  setRecordingsLoadDisabled(state) {
    state.nextRecordingsPage = false;
  },

  clearShows(state) {
    state.page = 1;
    state.shows = [];
    state.nextPage = true;
  },

  clearRecordings(state) {
    state.recordingsPage = 1;
    state.recordings = [];
    state.nextRecordingsPage = true;
  },
  setRecordings(state, resultData) {
    state.recordingsPage++;
    state.recordings = [...state.recordings, ...resultData.data];
    state.nextRecordingsPage = resultData.next;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
