<template>
  <AudioPlayerBase ref="streamPlayer" class="stream-player">
    <template v-slot:audio-player-control>
      <audio
        ref="streamsource"
        id="source"
        :src="streamUrl"
        preload="none"
      ></audio>
      <div
        ref="streamplayer"
        v-bind:class="streamPlayer.playerState"
        class="audio-player-control"
        id="streamplayer"
        v-on:click="playPause"
      >
        <span></span>
      </div>
    </template>
    <template v-slot:audio-player-source>
      <div v-if="streamPlayer.playingNow.live" class="playing-now-live">
        <span class="on-air-dot"></span>
        <small class="live-broadcast">
          On Air
        </small>
      </div>
      <div v-else class="playing-now-live">
        <span class="now">Now playing</span>
      </div>
    </template>
    <template v-slot:audio-player-title>
      <template v-if="streamPlayer.playingNow.recording_name">
        <router-link
          :to="{
            name: 'ArchiveDetail',
            params: {
              slug: streamPlayer.playingNow.show.slug,
              recording: streamPlayer.playingNow.recording_name
            }
          }"
          class="stream-player-metadata-title"
        >
          {{ nowPlaying }}
        </router-link>
      </template>
      <template v-else>
        {{ nowPlaying }}
      </template>
    </template>
    <template v-slot:audio-player-addon>
      <VolumeControl :audiosource="getAudioSource()" :sourceid="'source'" />
      <ToggleControl :streamplayer="true" />
    </template>
  </AudioPlayerBase>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import AudioPlayerBase from "@/components/AudioPlayer/AudioPlayerBase.vue";
import ToggleControl from "@/components/AudioPlayer/ToggleControl.vue";
import VolumeControl from "@/components/AudioPlayer/VolumeControl.vue";
import { getStreamUrl } from "@/helpers.js";
/* eslint-disable */

export default {
  name: "StreamPlayer",
  components: {
    AudioPlayerBase,
    ToggleControl,
    VolumeControl
  },
  computed: {
    streamUrl: function() {
      return getStreamUrl() + 
        "?nocache=" + 
        Math.random()
          .toString()
          .slice(2);
    },
    ...mapState({
      streamPlayer: state => state.streamPlayer
    }),
    ...mapState({
      volume: state => state.volume
    }),
    nowPlaying: function() {
      return this.getNowPlaying(this.streamPlayer.playingNow);
    },
    ...mapGetters("streamPlayer", ["changedContent"])
  },
  data: function() {
    return {
      me: this
    };
  },
  watch: {
    changedContent: function() {
      const streamSource = this.$refs.streamsource;
      const title = this.getNowPlaying(this.streamPlayer.playingNow);
      if (streamSource && !streamSource.paused) {
        this.setPlayingTitle(title);
        document.title = title;
        this.setMediaMetdata();
      }
      this.$root.$emit("play_content_changed");
    }
  },
  created() {
    const loadData = () => {
      this.getPlayingNow();
    };

    loadData();

    setInterval(function() {
      loadData();
    }, 10000);
  },
  mounted() {
    var streamSource = this.$refs.streamsource;
    var thisStore = this.$store;
    var streamPlayer = this;

    streamSource.volume = this.getVolumeLevel();
    const muted = this.getMute();
    if (muted) {
      streamSource.muted = true;
    }
 
    this.$root.$on("video_playing", () => {
      streamSource.pause();
      thisStore.dispatch("streamPlayer/updatePlayerState", "");
    });

    this.$root.$on("audioplayer_src_changed", src => {
      // console.log("StreamPlayer received axudioplayer_src_changed", src);
      if (streamSource.src !== src) {
        streamSource.pause();
        thisStore.dispatch("streamPlayer/updatePlayerState", "");
      }
    });

    this.$root.$on("show_stream_player", () => {
      const splayer = streamPlayer.$refs.streamPlayer;
      if (splayer) {
        splayer.$el.classList.remove("hidden-player");
      }
    });
 
    this.$root.$on("show_archive_player", () => {
      const splayer = streamPlayer.$refs.streamPlayer;
      if (splayer) {
        splayer.$el.classList.add("hidden-player");
      }
    });
 
    streamSource.onpause = function(e) {
      //console.log("AUDIO EVENT: streamSource.onpause", e);
      thisStore.dispatch("streamPlayer/updatePlayerState", "");
    };

    streamSource.onplay = function(e) {
      //console.log("AUDIO EVENT: streamSource.onplay ", e);
      thisStore.dispatch("streamPlayer/updatePlayerState", e.type);
      streamPlayer.setMediaMetdata();
      var videoPlayer =
        streamPlayer.$root.$children[0].$children[4].$refs.videoPlayer;
      if (videoPlayer) {
        videoPlayer.pause();
      }
    };

    streamSource.onplaying = function(e) {
      //console.log("AUDIO EVENT: streamSource.onplaying", e);
      thisStore.dispatch("streamPlayer/updatePlayerState", e.type);
    };

    streamSource.onwaiting = function(e) {
      //console.log("AUDIO EVENT: streamSource.onwaiting", e);
      thisStore.dispatch("streamPlayer/updatePlayerState", e.type);
    };

    streamSource.onabort = function(e) {
      //console.log("AUDIO EVENT: streamSource.onabort", e);
      thisStore.dispatch("streamPlayer/updatePlayerState", "");
      //streamSource.load();
      //streamSource.src = streamPlayer.streamUrl;
    };

    streamSource.onended = function(e) {
      //console.log("AUDIO EVENT: streamSource.onended", e);
      thisStore.dispatch("streamPlayer/updatePlayerState", "waiting");
      streamSource.load();
    };

    streamSource.onloadeddata = function(e) {
      //console.log("AUDIO EVENT: streamSource.onloadeddata", e);
      streamSource.play();
    };

    streamSource.onstalled = function(e) {
      //console.log("AUDIO EVENT: streamSource.onstalled", e);
      //streamSource.load();
    };

    // streamSource.oncanplay = function(e) {
    //   console.log("streamSource.oncanplay happened", e);
    // };

    // streamSource.oncanplaythrough = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.ondurationchange = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onemptied = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onerror = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onloadedmetadata = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onloadstart = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onprogress = function (e) {
    //     console.log("AUDIO EVENT: ", e)
    // };

    // streamSource.onratechange = function() {
    //  console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onseeked = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onseeking = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.onsuspend = function(e) {
    //   console.log("AUDIO EVENT: ", e);
    // };

    // streamSource.ontimeupdate = function (e) {
    //     console.log("AUDIO EVENT: ", e)
    // };

    // streamSource.onvolumechange = function() {
    //   console.log("AUDIO EVENT: ", e);
    // };
  },
  methods: {
    ...mapActions("streamPlayer", ["getPlayingNow"]),
    getVolumeLevel: function() {
      const streamSource = this.$refs.streamsource;
      return this.volume[streamSource.id].volumeLevel;
    },
    getMute: function() {
      const streamSource = this.$refs.streamsource;
      return this.volume[streamSource.id].mute;
    },
    getAudioSource: function() {
      return this.$refs.streamsource;
    },
    getInitialLevel: function() {
      const streamsource = this.$refs.streamsource;
      return localStorage.getItem(`${streamsource.id}_volume`);
    },
    getStreamUrlNoCache: function() {
      return getStreamUrl() + 
        "?nocache=" + 
        Math.random()
          .toString()
          .slice(2)
    },
    playPause: function() {
      var streamSource = this.$refs.streamsource;
      // console.log("streamPlayer.pause" , streamSource.paused);
      if (streamSource.paused) {
        this.play();
        this.setPlayingSrc({
          src: streamSource.src
        });
        this.setPlayingTitle(this.getNowPlaying(this.streamPlayer.playingNow));
      } else {
        this.pause();
      }
      // Prevent Default Action
      return false;
    },
    play: function() {
      var streamSource = this.$refs.streamsource;
      // console.log("stream called play", streamSource.paused);
      streamSource.play();
    },
    pause: function() {
      var streamSource = this.$refs.streamsource;
      // console.log("stream called pause", streamSource.paused);
      streamSource.pause();
    },
    formatArtists: function(artists, title_separator) {
      if (artists && artists.length > 0) {
        return (
          title_separator +
          " " +
          artists
            .map(function(elem) {
              return elem.name;
            })
            .join(" & ")
        );
      };
      return "";
    },
    formatDate: function(date) {
      return date ? new Date(date).toLocaleDateString("en-US", {
        month: "2-digit",
        year: "2-digit"
      }) : "";
    },
    getNowPlaying: function(playingNow) {
      if (playingNow.prerecorded) {
        const title = [
          playingNow.title,
          this.formatDate(playingNow.record_date),
          this.formatArtists(playingNow.artists, playingNow.title_separator)
        ];
        return title.join(" ");
      } else if (playingNow.live) {
        return playingNow.title + " " + this.formatArtists(playingNow.artists, playingNow.title_separator);
      } else {
        const title = [
          playingNow.title,
          this.formatDate(playingNow.record_date),
          this.formatArtists(playingNow.artists, playingNow.title_separator)
        ];
        return title.join(" ");
      }
    },
    setMediaMetdata: function() {
      const player = this;
      const streamSource = this.$refs.streamsource;
      const title = this.getNowPlaying(this.streamPlayer.playingNow);
      if ("mediaSession" in navigator) {
        let artwork = {
              src: "https://radiopunctum.cz/favicon/rp_logo_1000x1000.png",
              sizes: "1001x1001",
              type: "image/png",
            };
        if (this.streamPlayer.playingNow.image) {
          artwork = {
              src: this.streamPlayer.playingNow.image
            };
        }
        navigator.mediaSession.metadata = new MediaMetadata({
          title: title,
          artwork: [
              artwork
          ],
        });
        navigator.mediaSession.setActionHandler("play", () => {
          player.playPause();
        });
        navigator.mediaSession.setActionHandler("pause", () => {
          player.playPause();
        });
        navigator.mediaSession.setActionHandler("seekbackward", () => {
        });
        navigator.mediaSession.setActionHandler("seekforward", () => {
        });
      }
    },
    ...mapMutations("docTitle", ["setPlayingTitle"]),
    ...mapMutations("audioPlayer", ["setPlayingSrc"]),
    ...mapGetters("audioPlayer", ["getStreamPaused"])
  }
};
</script>

<style lang="scss">

@import "../../variables";
@-webkit-keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}
@-moz-keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}
@-o-keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}
@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.on-air-dot {
  -webkit-animation: blink 2s infinite;
  -moz-animation: blink 2s infinite;
  -o-animation: blink 2s infinite;
  animation: blink 2s infinite;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: $player-live-broadcast-color;
  border-radius: 10px;
}

audio {
  height: 0;
  width: 0;
  display: none;
}
.stream-player {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: $navigation-height-xs;
  border: 2px solid $navigation-bg;
  width: 100%;
  color: $navigation-color-alternative;
  //box-shadow: -15px 0px 15px 15px rgba(0, 0, 0, 0.15);
  background: $navigation-bg;
  z-index: 19;
  transition: height 0.5s, opacity 0.5s; /* Smooth transition */
  opacity: 1;
  padding-right: 8px;
  // @include blurred-bg($navigation-bg-alternative);

  &.hidden-player {
    height: 0;
  z-index: 20;
  opacity: 0;
    div, span, button, .audio-player-control, .audio-player-control::before {
      height: 0 !important;
      visibility: hidden;
    }
  }
}

.stream-player-metadata-title {
    color: $navigation-color-alternative;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

}
</style>
