import { localStorageRead, localStorageWrite } from "@/helpers.js";

const state = {
  source: {
    volumeLevel: 1,
    volumeIcon: "icon-volume-high",
    volumeIconOnMute: "icon-volume-high",
    mute: false,
    progressLevel: "152px",
    progressLevelOnMute: "152px"
  },
  archivesource: {
    volumeLevel: 1,
    volumeIcon: "icon-volume-high",
    volumeIconOnMute: "icon-volume-high",
    mute: false,
    progressLevel: "152px",
    progressLevelOnMute: "152px"
  }
};

const getters = {
  getMute: state => playerId => {
    return state[playerId].mute;
  },
  getProgressLevel: state => playerId => {
    return state[playerId].progressLevel;
  },
  getProgressLevelOnMute: state => playerId => {
    return state[playerId].progressLevelOnMute;
  },
  getVolumeLevel: state => playerId => {
    return state[playerId].volumeLevel;
  },
  getVolumeIcon: state => playerId => {
    return state[playerId].volumeIcon;
  },
  getVolumeState: state => playerId => {
    return state[playerId];
  }
};

const actions = {
  updateMute({ commit }, { playerId, bool }) {
    commit("setMute", { playerId, bool });
  },
  updateProgressLevel({ commit }, { playerId, progressLevel }) {
    commit("setProgressLevel", { playerId, progressLevel });
  },
  updateVolumeLevel({ commit }, { playerId, volumeLevel }) {
    commit("setVolumeLevel", { playerId, volumeLevel });
  },
  updateVolumeState({ commit }, { playerId, volumeState }) {
    commit("setVolumeState", { playerId, volumeState });
  },
  updateVolumeIcon({ commit }, { playerId, volumeIcon }) {
    commit("setVolumeIcon", { playerId, volumeIcon });
  },
  loadVolume({ commit }, playerId) {
    const volume = localStorageRead(`${playerId}_volume`);
    if (volume) {
      commit("setVolumeState", { playerId: playerId, volumeState: volume });
    }
  }
};

const mutations = {
  setMute(state, { playerId, bool }) {
    state[playerId].mute = bool;
  },
  setProgessLevel(state, { playerId, progressLevel }) {
    state[playerId].progressLevel = progressLevel;
  },
  setVolumeLevel(state, { playerId, volumeLevel }) {
    state[playerId].volumeLevel = volumeLevel;
  },
  setVolumeIcon(state, { playerId, volumeIcon }) {
    state[playerId].volumeIcon = volumeIcon;
  },
  setVolumeState(state, { playerId, volumeState }) {
    state[playerId].volumeLevel = volumeState.volumeLevel;
    state[playerId].volumeIcon = volumeState.volumeIcon;
    state[playerId].volumeIconOnMute = volumeState.volumeIconOnMute;
    state[playerId].mute = volumeState.mute;
    state[playerId].progressLevel = volumeState.progressLevel;
    state[playerId].progressLevelOnMute = volumeState.progressLevelOnMute;
    const toStore = {
      volumeLevel: state[playerId].volumeLevel,
      volumeIcon: state[playerId].volumeIcon,
      volumeIconOnMute: state[playerId].volumeIconOnMute,
      mute: state[playerId].mute,
      progressLevel: state[playerId].progressLevel,
      progressLevelOnMute: state[playerId].progressLevelOnMute
    };
    localStorageWrite(`${playerId}_volume`, toStore);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
