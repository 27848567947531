<template>
  <div
    v-if="!isMobile()"
    class="volume-control"
    v-on:mouseout="hideSlider()"
    v-on:mouseover="showSlider()"
  >
    <div class="volume-icon">
      <div :class="volumeIcon" v-on:click="toggleMute()"></div>
    </div>
    <div class="volume-slider" title="Volume Slider" ref="volumeslider">
      <div class="slider-hover-box" ref="sliderhoverbox"></div>
      <div class="slider-background" ref="sliderbackground">
        <div class="slider-progress" ref="sliderprogress"></div>
        <div class="slider-handle" ref="sliderhandle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { isMobile } from "@/helpers.js";

const logDBRange = 60;
const log_a = 1 / 10 ** (logDBRange / 20);
const log_b = Math.log(1 / log_a);
const log_rolloff = 10 * log_a * Math.exp(log_b * 0.1);

export default {
  name: "VolumeControl",
  props: ["audiosource", "sourceid"],
  computed: {
    ...mapState({
      volume: state => state.volume
    })
  },
  data: function() {
    return {
      mousedown: this.mousedown,
      volumeIcon: "icon-volume-high",
      iconBeforeMute: "icon-volume-high",
      logaritmic: true,
      progressPos: this.progressPos
    };
  },
  created() {},
  mounted: function() {
    const slider = this.$refs.volumeslider;
    if (slider) {
      slider.addEventListener("mousedown", this.eventHandler);
      this.loadVolume(this.sourceid);
      this.volumeIcon = this.getVolume().volumeIcon;
    }
  },
  methods: {
    isMobile,
    getVolume: function() {
      return this.volume[this.sourceid];
    },
    calculateVolume: function(x) {
      let vol = 0;
      vol = log_a * Math.exp(log_b * x);
      if (x < 0.1) {
        vol = x * log_rolloff;
      }
      // Clip to [0,1] to account for possible rounding errors
      vol = Math.max(Math.min(vol, 1.0), 0.0);
      return vol;
    },
    updateVolume: function(value, progressLevel) {
      const level = parseInt(value * 100 - 7.878);
      //console.log(`Value set to: ${value}`);
      //console.log(`Level set to: ${level}`);
      const volume = this.calculateVolume(value);
      //console.log(`Volume set to: ${volume}`);
      this.audiosource.volume = volume;
      if (level == 0) {
        this.volumeIcon = "icon-volume-mute2";
        this.audiosource.muted = true;
      } else if (level < 30) {
        this.volumeIcon = "icon-volume-low";
        this.audiosource.muted = false;
      } else if (level < 80) {
        this.volumeIcon = "icon-volume-medium";
        this.audiosource.muted = false;
      } else {
        this.volumeIcon = "icon-volume-high";
        this.audiosource.muted = false;
      }
      this.updateVolumeState({
        playerId: this.sourceid,
        volumeState: {
          volumeLevel: volume,
          volumeIcon: this.volumeIcon,
          volumeIconOnMute: this.volumeIcon,
          mute: this.audiosource.muted,
          progressLevel: progressLevel,
          progressLevelOnMute: progressLevel
        }
      });
    },
    toggleMute: function() {
      const handle = this.$refs.sliderhandle;
      const progress = this.$refs.sliderprogress;
      if (!this.audiosource) return;
      if (this.audiosource.muted) {
        this.progressPos = this.getVolume().progressLevelOnMute;
        this.volumeIcon = this.getVolume().volumeIconOnMute;
        this.iconBeforeMute = this.getVolume().volumeIconOnMute;
        this.audiosource.muted = false;
        if (this.progressPos == undefined) {
          this.progressPos = "10px";
          this.iconBeforeMute = "icon-volume-low";
        }
        //this.volumeIcon = this.iconBeforeMute;
        handle.style.bottom = this.progressPos;
        progress.style.height = this.progressPos;
        this.updateVolumeState({
          playerId: this.sourceid,
          volumeState: {
            volumeLevel: this.audiosource.volume,
            volumeIcon: this.volumeIcon,
            volumeIconOnMute: this.iconBeforeMute,
            mute: this.audiosource.muted,
            progressLevel: this.progressPos,
            progressLevelOnMute: this.progressPos
          }
        });
      } else {
        this.audiosource.muted = true;
        this.iconBeforeMute = this.volumeIcon;
        this.volumeIcon = "icon-volume-mute2";
        this.progressPos = handle.style.bottom;
        handle.style.bottom = 0;
        progress.style.height = 0;
        this.updateVolumeState({
          playerId: this.sourceid,
          volumeState: {
            volumeLevel: this.audiosource.volume,
            volumeIcon: this.volumeIcon,
            volumeIconOnMute: this.iconBeforeMute,
            mute: this.audiosource.muted,
            progressLevel: "0px",
            progressLevelOnMute: this.progressPos
          }
        });
      }
    },
    showSlider: function() {
      const slider = this.$refs.volumeslider;
      const handle = this.$refs.sliderhandle;
      const progress = this.$refs.sliderprogress;
      this.progressPos = this.getVolume().progressLevel;
      slider.classList.add("expanded");
      //slider.classList.remove("collapsed");
      if (this.progressPos) {
        handle.style.bottom = this.progressPos;
        progress.style.height = this.progressPos;
      }
    },
    hideSlider: function() {
      const slider = this.$refs.volumeslider;
      const handle = this.$refs.sliderhandle;
      const progress = this.$refs.sliderprogress;
      if (this.mousedown) {
        return;
      }
      //slider.classList.add("collapsed");
      slider.classList.remove("expanded");
      this.progressPos = handle.style.bottom;
      handle.style.bottom = "";
      progress.style.height = "";
    },
    eventHandler: function(e) {
      const me = this;
      const slider = this.$refs.sliderbackground;
      const handle = this.$refs.sliderhandle;
      const progress = this.$refs.sliderprogress;
      const sliderRect = slider.getBoundingClientRect();
      const offsetY = e.clientY - sliderRect.top;
      me.mousedown = true;
      handle.style.transition = "none";
      progress.style.transition = "none";
      // Set the initial position of the handle
      let handlePosition = Math.max(
        0,
        Math.min(offsetY, sliderRect.height - handle.offsetHeight)
      );
      let progressLevel = `${sliderRect.height -
        handlePosition -
        handle.offsetHeight}px`;
      handle.style.bottom = progressLevel;
      progress.style.height = progressLevel;
      // Update volume based on position
      me.updateVolume(
        (sliderRect.height - handlePosition) / sliderRect.height,
        progressLevel
      );
      // Dragging functionality
      const onMouseMove = e => {
        const newY = e.clientY - sliderRect.top;
        handlePosition = Math.max(
          0,
          Math.min(newY, sliderRect.height - handle.offsetHeight)
        );
        progressLevel = `${sliderRect.height -
          handlePosition -
          handle.offsetHeight}px`;
        handle.style.bottom = progressLevel;
        progress.style.height = progressLevel;
        me.updateVolume(
          (sliderRect.height - handlePosition) / sliderRect.height,
          progressLevel
        );
      };
      const onMouseUp = () => {
        const handle = this.$refs.sliderhandle;
        const progress = this.$refs.sliderprogress;
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
        me.mousedown = false;
        handle.style.transition = "";
        progress.style.transition = "";
      };
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    },
    ...mapActions("volume", ["updateVolumeState", "loadVolume"]),
    ...mapGetters("volume", ["getVolumeState"])
  }
};
</script>

<style lang="scss">
@import "../../variables";
@import "../../mixins";
.volume-icon {
  min-width: 1.2em;
  position: relative;
  height: $navigation-height-xs;
  display: flex;
  //top: 8px;
  //flex-direction: column;
  align-items: center;
  //justify-content: center;
  font-size: 20px;
  cursor: pointer;

  .volume-scale-toggle {
    margin-top: 5px;
    border-radius: 999px;
  }
}

.volume-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 10px;
  padding: 0 10px;
}

.volume-slider {
  height: 0; /* Height of the slider */
  width: 50px; /* Width of the slider */
  background: $footer-lower-anchor-color; /* Background color */
  border: 1px solid $footer-lower-anchor-color;
  border-radius: 45px; /* Rounded corners */
  position: absolute; /* Positioning context for the handle */
  overflow: visible;
  transition-property: height;
  transition-duration: 0.1s; /* Smooth transition */
  opacity: 0;
  z-index: 33;
  padding: 15px 0;
  cursor: pointer; /* Change cursor on hover */
  //box-shadow: 0 0 20px 2px rgba(255, 255, 255, 0.15);
  left: -5px;

  &.expanded {
    bottom: $navigation-height-xs - 20px;
    height: 200px;
    opacity: 1;
  }
}

.slider-handle {
  width: 13px; /* Full width of the slider */
  height: 13px; /* Height of the handle */
  background: black; /* Handle color */
  position: absolute; /* Positioning */
  left: -5px;
  cursor: pointer; /* Change cursor on hover */
  border-radius: 10px; /* Rounded corners */
  opacity: 0;
  //margin: 15px 0;
  transition: bottom 0.1s; /* Smooth transition */
  bottom: $navigation-height-xs - 70px;
}

.volume-slider.expanded .slider-handle {
  bottom: 155px;
  opacity: 1;
}

.slider-background {
  width: 2px; /* Full width of the slider */
  height: 0; /* Height of the handle */
  background: gray; /* Handle color */
  //border: 2px solid gray;
  position: absolute; /* Positioning */
  left: 23px;
  cursor: pointer; /* Change cursor on hover */
  transition-property: height;
  transition-duration: 0.1s; /* Smooth transition */
  opacity: 0;
  bottom: $navigation-height-xs - 65px;
  pointer-events: none;
}

.volume-slider.expanded .slider-background {
  height: 165px;
  opacity: 1;
}

.slider-progress {
  width: 2px; /* Full width of the slider */
  height: 0; /* Height of the handle */
  background: black; /* Handle color */
  position: absolute; /* Positioning */
  //left: 22px;
  bottom: $navigation-height-xs - 80px;
  transition: height 0.1s; /* Smooth transition */
  border-radius: 2px; /* Rounded corners */
  opacity: 0;
  pointer-events: none;
}

.volume-slider.expanded .slider-progress {
  height: 155px;
  opacity: 1;
}

.volume-slider.expanded .slider-hover-box {
  position: absolute;
  width: 100px;
  height: 250px;
  //border: 1px solid red;
  bottom: 20px;
  left: -20px;
  border-radius: 70px 0 0 0;
}
</style>
